<template>
  <div class="home">
    <!-- <div
      class="absolute-wrapper"
      v-if="showModal || showSuccessToast || showIMEI"
    ></div>
    <IMEImodal v-if="showIMEI" @closeIMEIModal="handlecloseIMEIModal" />
    <HomeModalComponent
      v-if="showModal"
      @closeModal="handleCloseModal"
      @showSuccess="handleShowSuccess"
    />
    <HomeModalToastSuccess
      v-if="showSuccessToast"
      @closeModal="handleCloseSuccessModal"
    />
    <div class="wrapper">
      <img class="logo" src="@/assets/xiaomi_logo.png" alt="" />
      <div class="logo-title-wrap">
        <h1>Kupili ste jedan od ova dva telefona</h1>
      </div>
      <p>Vreme je da se prijavite za poklon!</p>
      <div class="warranty-wrap">
        <a href="static/garancija.pdf" target="__blank"><img src="@/assets/warranty.png" alt=""></a>
      </div>
      <div class="flex-wrap">
        <div class="flex-wrap-left">
          <img src="@/assets/levo.png" alt="phones1" />
        </div>
        <div class="flex-wrap-right">
          <img src="@/assets/desno.png" alt="phones2" />
        </div>
      </div>
      <div class="present">
        <img src="@/assets/pkln.png" alt="phones2" />
      </div>
      <div class="button-container">
        <button class="choose-gift" @click="showModal = !showModal">
          Prijava za poklon
        </button>
        <button class="rules" @click="handleShowIMEI">
          Kako do IMEI broja?
        </button>
      </div>
      <div class="support-mail">
        <span>Ukoliko imate problem sa prijavom obratite nam se na</span>
        <span>xiaomi.prijava@gmail.com</span>
      </div>
    </div> -->
  </div>
</template>

<script>
// import HomeModalComponent from "../components/HomeModalComponent.vue";
// import HomeModalToastSuccess from "../components/HomeModalToastSuccess.vue";
// import IMEImodal from "../components/IMEImodal.vue";
export default {
  components: {
    // HomeModalComponent,
    // HomeModalToastSuccess,
    // IMEImodal,
  },
  data() {
    return {
      showModal: false,
      showSuccessToast: false,
      showIMEI: false,
    };
  },
  methods: {
    handleCloseModal() {
      this.showModal = false;
    },
    handleCloseSuccessModal() {
      this.showSuccessToast = false;
    },
    handleShowSuccess() {
      this.showModal = false;
      this.showSuccessToast = true;
    },
    handleShowIMEI() {
      this.showIMEI = true;
    },
    handlecloseIMEIModal() {
      this.showIMEI = false;
    },
  },
};
</script>

<style scoped lang='scss'>
.home {
  position: relative;
  .absolute-wrapper {
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 0.9;
    z-index: 10;
    position: absolute;
  }
  .wrapper {
    max-width: 1320px;
    margin: 0 auto;
    padding: 120px 120px 120px 120px;
    .logo {
      display: block;
      width: 88px;
      height: 88px;
      margin:  0 0 45px calc(100% - 88px);
    }
    .warranty-wrap {
      margin: 0 0 30px 0;
    }
    .logo-title-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-family: Xiaomi-Bold;
        font-size: 60px;
        line-height: 73px;
        letter-spacing: -0.01em;
        width: 50%;
        color: #22242c;
      }
    }
    & > p {
      font-family: Xiaomi-Regular;
      font-size: 32px;
      line-height: 27px;
      color: #4c4e52;
      margin: 40px 0 100px 0;
    }
    .flex-wrap {
      height: 700px;
      display: flex;
      justify-content: space-between;
      img {
        display: inline-block;
        width: 100%;
      }
      .phone-model-data {
        border-left: 4px solid #e7e7e7;
        padding: 0 9px;
        h2 {
          font-family: Xiaomi-Regular;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 31px;
          color: #212121;
        }
        p {
          font-family: Xiaomi-Regular;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 31px;
          color: #ff6900;
        }
      }
      .flex-wrap-left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
          max-width: 580px;
        }
        .phone-model-data {
          align-self: center;
          transform: translateX(-75%);
          margin-bottom: 35px;
        }
      }
      .flex-wrap-right {
        // align-self: flex-start;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
          max-width: 580px;
        }
      }
    }
    .present {
      position: relative;
      margin: 30px 0 50px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: inline-block;
        width: 583px;
        max-width: 70%;
        margin: 0 auto;
      }
    }
    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin: 0 auto;
      button {
        width: 201px;
        height: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-family: Xiaomi-Regular;
        border: none;
        outline: none;
        cursor: pointer;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
      }
      .choose-gift {
        background: #ff6900;
        color: #ffffff;
      }
      .rules {
        background: #fff;
        color: #ff6900;
      }
    }
  }
  .support-mail {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      display: inline-block;
      text-align: center;
    }
    span:nth-of-type(2) {
      color: #ff6900;
    }
  }
}
@media only screen and (max-width: 1080px) {
  .home {
    .wrapper {
      .logo {
        margin: 0 auto;
      }
      .warranty-wrap {
        text-align: center;
        margin: 0 0 50px 0;
      }
      .logo-title-wrap {
        flex-direction: column-reverse;
        h1 {
          text-align: center;
          width: 100%;
          margin-top: 40px;
        }
      }
      & > p {
        text-align: center;
      }
      .flex-wrap {
        flex-direction: column;
        height: unset;
        img {
          max-width: 400px;
        }
        .flex-wrap-left {
          width: unset;
          align-self: center;
          justify-content: center;
          margin-bottom: 75px;
          .phone-model-data {
            transform: none;
          }
        }
        .flex-wrap-right {
          width: unset;
          align-self: center;
          margin-bottom: 75px;
        }
      }
      .button-container {
        margin: 100px 0 0 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home {
    .wrapper {
      padding: 40px;
      .logo-title-wrap {
        h1 {
          font-size: 30px;
          line-height: 40px;
        }
      }
      & > p {
        font-size: 20px;
        margin: 40px 0 100px 0;
      }
    }
    .button-container {
      button {
        font-size: 15px !important;
      }
    }
  }
}
</style>