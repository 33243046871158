<template>
  <div class="admin-panel-nav">
    <div class="admin-panel-top">
      <span> Admin Panel </span>
      <div class="admin-panel-top-controls">
        <div class="input-wrap">
          <div class="icon-wrap">
            <img
              src="@/assets/icons/magnifying_glass_icon.png"
              alt="magifying_glass"
            />
          </div>
          <input
            type="text"
            placeholder="Pretraga..."
            v-model="stringToSearch"
            @input="searchSubmissions"
          />
        </div>
        <div class="xiaomi-logo-wrap">
          <img src="@/assets/xiaomi_logo.png" alt="xiaomi_logo" />
        </div>
        <div class="off-button" @click="logout">
          <img src="@/assets/off_button.svg" alt="off_btn" />
        </div>
      </div>
    </div>
    <div class="admin-panel-bottom-controls">
      <div class="button-controls">
        <span
          ref="all"
          :class="{ selected: filters.all }"
          @click="selectFilter('all')"
          >Svi</span
        >
        <span
          ref="unconfirmed"
          :class="{ selected: filters.unconfirmed }"
          @click="selectFilter('unconfirmed')"
          >Nepotvrđeni</span
        >
        <span
          ref="refused"
          :class="{ selected: filters.refused }"
          @click="selectFilter('refused')"
          >Odbijeni</span
        >
        <span
          ref="confirmed"
          :class="{ selected: filters.confirmed }"
          @click="selectFilter('confirmed')"
          >Odobreni</span
        >
      </div>
      <div class="sent-filter-controls">
        <span>
          <input
            :disabled="!filters.confirmed"
            type="radio"
            name="sent-filter"
            @change="$emit('sent', 'null')"
            ref="radio1"
          />
          <span :style="disabled"> Nije poslato </span>
        </span>
        <span>
          <input
            :disabled="!filters.confirmed"
            type="radio"
            name="sent-filter"
            @change="$emit('sent', '1')"
            ref="radio2"
          />
          <span :style="disabled"> Poslato </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
export default {
  data() {
    return {
      filters: {
        all: false,
        unconfirmed: false,
        refused: false,
        confirmed: false,
      },
      stringToSearch: "",
      searchResults: [],
      timeOutForSearch: null,
      selectedFilter: null,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        const keys = Object.keys(this.filters);
        for (const key of keys) {
          if (this.filters[key] == true) {
            this.$emit("filter", key);
          }
          if (key != "confirmed") {
            this.$refs.radio1.checked = false
            this.$refs.radio2.checked = false
          }
        }
      },
    },
  },
  computed: {
    disabled() {
      return !this.filters.confirmed ? { color: "#c3c3c3" } : {};
    },
    ...mapState(["sid"]),
  },
  methods: {
    selectFilter(ref) {
      const keys = Object.keys(this.filters);
      for (const key of keys) {
        if (ref == key) {
          this.filters[key] = true;
        } else {
          this.filters[key] = false;
        }
      }
    },
    async searchSubmissions() {
      if (this.timeOutForSearch) clearTimeout(this.timeOutForSearch);
      this.timeOutForSearch = setTimeout(async () => {
        this.$emit("searchResults", {
          searchResults: this.searchResults,
          string: this.stringToSearch,
          filter: this.selectedFilter,
        });
      }, 600);
    },
    async logout() {
      const sid = this.sid ? this.sid : localStorage.getItem("sid");
      try {
        const res = await api.logout({ sid });
        if (res.status == 200) {
          localStorage.removeItem("sid");
          this.$router.push("/admin-login");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.selectFilter("all");
  },
};
</script>

<style scoped lang='scss'>
.admin-panel-nav {
  height: 160px;
  background: #f7f5f5;
  .admin-panel-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 96%;
      top: 100%;
      height: 1px;
      background: #dddddd;
    }
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 30px;
      color: #394050;
    }
    .admin-panel-top-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      .input-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 426px;
        height: 50px;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 0 20px;
        .icon-wrap {
          margin-right: 15px;
        }
        input {
          width: 100%;
          height: 100%;
          border: none;
          background-color: $backgroundGray;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 20px;
          color: $inputText;
          &:focus {
            outline: none;
          }
        }
      }
      .xiaomi-logo-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 25px;
        width: 54px;
        height: 54px;
        img {
          display: block;
          width: 100%;
        }
      }
      .off-button {
        cursor: pointer;
      }
    }
  }
  .admin-panel-bottom-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 800px;
    padding: 0 40px;
    .button-controls {
      margin: 20px 0;
      padding: 0 10px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 700px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      position: relative;
      color: #585e6d;
      height: 100%;
      &::after {
        content: "";
        width: 1px;
        height: 45px;
        position: absolute;
        background: #dddddd;
        left: 100%;
      }
      span {
        cursor: pointer;
        display: inline-block;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 9px 23px 8px 27px;
        transition: all 0.3s ease-in-out;
      }
    }
    .sent-filter-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 20px;
      color: #585e6d;

      input {
        display: inline-block;
        margin-right: 10px;
        margin-left: 10px;
        transition: all 0.1s ease-in-out;
      }
      span {
        min-width: 140px;
        display: flex;
        align-items: center;
        transition: all 0.1s ease-in-out;
      }
    }
  }
}
.selected {
  color: $orange;
  background: rgba(248, 102, 0, 0.14);
  transition: all 0.3s ease-in-out;
}
</style>